<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 mt-3">
        <addHeader
          :title="$t('projects.form.AddAProject')"
          :description="$t('projects.form.FromHereYouCanAddANewProject')"
          v-if="$route.name == 'projectsCreate'"
        />
        <addHeader
          :title="$t('AmendmentOfTheProject')"
          :description="$t('projects.form.FromHereYouCanAddANewProject')"
          v-if="$route.name == 'projectsEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('projects.form.basicInformation')"
            :description="$t('projects.form.BasicProjectInformation')"
            noLine="true"
          />

          <formInput
            :title="$t('projects.form.ProjectName')"
            v-model="item.name"
            :hasErorr="errors.name"
          />

          <monyInput 
            :title="$t('projects.form.ProjectBalance')" 
            v-model="item.balance" 
            :disabled="this.$route.name == 'projectsEdit'? true : false" 
            :checkError="'nameErorr'"
            :hasErorr="errors.balance"
            :error="$t('allerts.thisFieldIsRequired')"/>

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{$t('projects.form.TheHistoryOfTheBad')}}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>

                <VueCtkDateTimePicker
                  :label="$t('projects.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('projects.form.Now')"
                  v-model="item.start_date"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"/>
              </div>
            </div>
          </div>
          
          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{$t('projects.form.ExpirationDate')}}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>

                <VueCtkDateTimePicker
                  :label="$t('projects.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('projects.form.Now')"
                  v-model="item.end_date"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"/>
              </div>
            </div>
          </div>

          <monyInput 
            :title="$t('projects.form.ProjectedCost')" 
            v-model="item.expected_cost" 
            :checkError="'nameErorr'"
            :hasErorr="errors.expected_cost"
            :error="$t('allerts.thisFieldIsRequired')"/>

          <monyInput 
            :title="$t('projects.form.ExpectedPrice')" 
            v-model="item.expected_price" 
            :checkError="'nameErorr'"
            :hasErorr="errors.expected_price"
            :error="$t('allerts.thisFieldIsRequired')"/>

          <formInput
            type="number"
            :title="$t('projects.form.NumberOfUnits')"
            v-model="item.number_of_units"
            :hasErorr="errors.number_of_units"
          />

          <selectInput
            :title="$t('projects.form.Status')"
            v-model="item.status"
            :values="[
              { name: $t('projects.form.ItIsOpen'), value: 0 },
              { name: $t('projects.form.InProgress'), value: 1 },
              { name: $t('projects.form.Very'), value: 2 },
              { name: $t('projects.form.canceled'), value: 3 },
            ]"
          />

          <formTextarea
            :title="$t('projects.form.ProjectDescription')"
            v-model="item.description"
          />

          <dvider
            :title="$t('projects.form.ProjectOutputs')"
            :description="$t('projects.form.FinalOutputsOfTheProject')"
          />
          <productsTable class="col-12" />



          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="
              $route.name == 'projectsCreate' && !$parent.stopEdit
            "
          >
            {{$t('projects.form.AddAProject')}}
          </a>
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="
              $route.name == 'projectsEdit' && !$parent.stopEdit
            "
          >
            {{$t('projects.form.AmendmentOfTheProject')}}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";

import formInput from "../elements/add/form/formInput.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";

import productsTable from "../elements/add/form/projects/productsTable.vue";

import loading from "../elements/add/loading.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/projects",
      item: {
        invoice_group: this.$option.project_invoice_group_id,
        status: 0,
        start_date: "",
        end_date: "",
        projectItems: [],
        ProductsCount: 0,
      },
      errors: {
        name: false,
      },
      itemIndex: null,
    };
  },
  mounted() {
    this.$updateDatabase([
      "products",
      "InvoiceGroups"
    ]);
    if (this.$route.name == "projectsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    }
    if (this.$route.name == "projectsCreate") {
      this.addQuotationItem();
      this.item.start_date = this.$nowDate()
      this.item.end_date = this.$nowDate(5)
    }

    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.$parent.stopEdit = false;
          this.item = response.data;
          if (this.item.projectItems.length == 0) {
            this.addQuotationItem();
          }
        });
    },
    saveItem() {
      var error = 0;
      this.errors = [];
      
      if (error == 0) {
        this.item.start_date = this.$fixDate(this.item.start_date);
        this.item.end_date = this.$fixDate(this.item.end_date);
        if (this.$route.name == "projectsCreate") {
          this.$parent.aletText = "تم اضافة المشروع بنجاح";
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "projectsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then(() => {
              this.$parent.stopEdit = false;

              this.$parent.aletText = "تم تعديل المشروع بنجاح";
              this.$parent.alertType = "success";
              this.$router.go(-1);
              this.loading = false;
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    addQuotationItem() {
      this.item.projectItems.push({
        name: "",
        description: "",
      });
    },
  },
  components: {
    dvider,
    addHeader,
    formInput,
    connectionInput,
    formTextarea,
    monyInput,
    selectInput,
    productsTable,
    loading,
    checkInput,
    VueCtkDateTimePicker,
  },
  watch: {
    "$option.invoice_invoice_group_id": function (val) {
      this.item.invoice_group = val;
    },
  },
};
</script>
